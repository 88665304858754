import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { getAdmin } from '../../../services/ApiServices';

export default function TermOfService() {
  useEffect(() => {
    window.scrollTo(0, 0); getAdminData();
  }, []);

  const [orgData, setOrgData] = useState([]);

  const getAdminData = async () => {
    const response = await getAdmin(1).catch(console.error);
    setOrgData(response.data);
  }

  return (
    <div className='container my-5'>
      <h4>Terms of Service for BnB Turf</h4>
      <p>Please read these Terms of Service ("Terms") carefully before using the BnB Turf website ("website"). By accessing or using the website, you agree to be bound by these Terms, as well as any additional terms and conditions provided within specific areas of the website. If you do not agree with any provisions of these Terms, please refrain from using the website.</p>
      <ol type='1'>
        <li className='fw-bold h5'>Services:</li>
        <ul>
          <li>BnB Turf operates an online platform that allows users to list, discover, and book accommodations ("Services").</li>
          <li>Users may create listings, search for accommodations, communicate with hosts, and make bookings through the website.</li>
          <li>BnB Turf acts as an intermediary platform and is not a party to any booking or rental agreements between users.</li>
        </ul>
        <li className='fw-bold h5 mt-4'>User Responsibilities:</li>
        <ul>
          <li>Users are responsible for providing accurate and complete information when creating an account or making a booking.</li>
          <li>Users must comply with applicable laws and regulations, as well as any additional rules or policies provided by BnB Turf.</li>
          <li>Users agree not to engage in any fraudulent, illegal, or harmful activities on the website.</li>
        </ul>
        <li className='fw-bold h5 mt-4'>Booking and Payments:</li>
        <ul>
          <li>When making a booking, users agree to comply with the specified cancellation policy and make the required payment.</li>
          <li>BnB Turf facilitates payments between users but does not assume any liability for financial transactions or disputes between users.</li>
          <li>Users are responsible for any additional fees, such as cleaning fees or taxes, specified by the host.</li>
        </ul>
        <li className='fw-bold h5 mt-4'>Content:</li>
        <ul>
          <li>Users may create and post content on the website, such as listings, reviews, or messages.</li>
          <li>Users retain ownership of their content but grant BnB Turf a non-exclusive, worldwide, royalty-free license to use, display, and distribute the content for the purpose of operating and promoting the Services.</li>
          <li>Users are responsible for the accuracy and legality of their content and must not infringe upon any third-party rights.</li>
        </ul>
        <li className='fw-bold h5 mt-4'>Host Responsibilities:</li>
        <ul>
          <li>Hosts are responsible for providing accurate and up-to-date information about their accommodations, including availability, pricing, and amenities.</li>
          <li>Hosts must maintain the safety and cleanliness of their accommodations, comply with applicable laws and regulations, and respect the privacy of guests.</li>
          <li>Hosts are solely responsible for any liability arising from the use of their accommodations.</li>
        </ul>
        <li className='fw-bold h5 mt-4'>Guest Responsibilities:</li>
        <ul>
          <li>Guests must treat the accommodations with respect, follow any house rules provided by the host, and communicate any concerns or issues promptly.</li>
          <li>Guests are responsible for any damage or loss caused during their stay, and they may be charged by the host accordingly.</li>
        </ul>
        <li className='fw-bold h5 mt-4'>Termination:</li>
        <p>BnB Turf reserves the right to suspend or terminate user accounts or access to the Services at any time and for any reason, including but not limited to violation of these Terms.</p>
        <li className='fw-bold h5 mt-4'>Disclaimers:</li>
        <ul>
          <li>BnB Turf does not endorse or warrant the accuracy, adequacy, or quality of any listings, accommodations, or user content.</li>
          <li>BnB Turf does not assume any responsibility for any actions or omissions of users, including hosts and guests.</li>
          <li>Users acknowledge and agree that their use of the website and Services is at their sole risk.</li>
        </ul>
        <li className='fw-bold h5 mt-4'>Limitation of Liability:</li>
        <p>To the maximum extent permitted by law, BnB Turf and its affiliates, officers, directors, employees, agents, and suppliers shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, revenue, data, or use arising out of or in connection with the use or inability to use the website or Services.</p>
        <li className='fw-bold h5 mt-4'>Governing Law and Jurisdiction:</li>
        <p>These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or relating to these Terms or the use of the website shall be resolved exclusively in the courts located in [Jurisdiction].</p>
        <li className='fw-bold h5 mt-4'>Modifications:</li>
        <p>BnB Turf reserves the right to modify or discontinue the website, its features, or these Terms at any time without prior notice. It is your responsibility to review these Terms periodically for any changes.</p>
        <li className='fw-bold h5 mt-4'>Contact Information:</li>
        <p>If you have any questions, concerns, or feedback regarding these Terms, please contact us at:</p>
        {orgData && <>
          Email ID.: <Link to={`mailto:${orgData.email_id}`} className='text-dark fw-bold'>{orgData.email_id}</Link><br />
          <div className='d-flex'>Phone No.:
            <div className='ms-1'>
              <Link to={`tel:${orgData.contact_number_one}`} className='text-dark fw-bold'>{orgData.contact_number_one}</Link><br />
              <Link to={`tel:${orgData.contact_number_two}`} className='text-dark fw-bold'>{orgData.contact_number_two}</Link>
            </div>
          </div>
          <div className='d-flex'>Address:<div className='text-dark fw-bold ms-1'> {orgData.address_line}{', '}{orgData.city}{', '}{orgData.city}{', '}{orgData.district}{', '}{orgData.state}{' - '}{orgData.pin_code}</div></div>
        </>}
        <p>By using the BnB Turf website, you signify your acceptance of these Terms of Service.</p>
      </ol>
    </div>
  )
}
