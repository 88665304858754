import React, { useEffect, useState } from 'react'
import Header from '../../common/Header';
import { Outlet } from 'react-router-dom';
import Footer from '../../common/Footer';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { getAdmin } from '../../../services/ApiServices';
import { ImageComponent } from '../../../services/ShortFunctions';

export default function Site() {
  const [orgData, setOrgData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0); getAdminData();
  }, []);

  const getAdminData = async () => {
    const response = await getAdmin(1).catch(console.error);
    setOrgData(response.data);
  }

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <WhatsAppWidget CompanyIcon={ImageComponent} phoneNumber={orgData.contact_number_one} className='z-3' />
    </>
  );
}
