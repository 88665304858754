import { useEffect, useState } from 'react';
import { IMAGE_BASE_URL } from '../../constants/DataConstants';
import { getAdmin } from '../ApiServices';

// To set <img src='' alt=''> Tag 'src' attribute value
export const ImagePath = (image_path) => IMAGE_BASE_URL + image_path?.substring(7);

// To convert string to HTML
export const StringToHTML = (str) => <div dangerouslySetInnerHTML={{ __html: str }} />;

export const ImageComponent = () => {
  useEffect(() => { getAdminData(); }, []);

  const [orgData, setOrgData] = useState([]);

  const getAdminData = async () => {
    const response = await getAdmin(1).catch(console.error);
    setOrgData(response.data);
  }

  return <img src={ImagePath(orgData.org_logo)} alt="" />
};