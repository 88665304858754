// export const BASE_URL = 'http://127.0.0.1:8000/api/';
// export const BASE_URL = 'https://turfapi.atjoindemowebsite.in.net/public/api/';
export const BASE_URL =
  "https://bnbturfapi.atjoindemowebsite.in.net/public/api/";

export const PHONEPE_PLESK_URL = "https://sndlmsapi.atopd.in/api/";
// export const IMAGE_BASE_URL = 'http://127.0.0.1:8000/storage/';
// export const IMAGE_BASE_URL = 'https://turfapi.atjoindemowebsite.in.net/public/storage/';
export const IMAGE_BASE_URL =
  "https://bnbturfapi.atjoindemowebsite.in.net/public/storage/";

// User Credentials Start
export const USER_ID = JSON.parse(localStorage.getItem("loggedInUser"));
export const ADMIN_DATA = JSON.parse(localStorage.getItem("adminLogin"));
// User Credentials End

// Payment Constants Start
export const ONLINE_ORDER_DETAILS = JSON.parse(
  localStorage.getItem("OnlineOrderDetails")
);
export const PAYMENT_DETAILS = JSON.parse(
  localStorage.getItem("PaymentDetails")
);
export const BOOKING_DETAILS = localStorage.getItem("BookingDetails");
export const CONTACT_NUMBER = JSON.parse(
  localStorage.getItem("contact_number")
);

// export const SALT_KEY = '0d8b9f31-5d27-48a7-9405-d83f80d2944a';
// export const MERCHANT_ID = 'ATJOINONLINE';
export const SALT_KEY = "BnBTurfSaltKey";
export const MERCHANT_ID = "MYTURFONLINE";
// Payment Constants Start
