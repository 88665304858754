import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// Site Components
import Site from "../../components/site/Site";
import SignIn from "../../components/site/SignIn";
import SignUp from "../../components/site/SignUp";
import AboutUs from "../../components/site/AboutUs";
import Home from "../../components/site/Home";
import ContactUs from "../../components/site/ContactUs";
import Courts from "../../components/site/Courts";
import Services from "../../components/site/Services";
import TurfDetails from "../../components/site/TurfDetails";
import BookingForm from "../../components/site/BookingForm";
import NewUser from "../../components/site/NewUser";
import VerifyUser from "../../components/site/VerifyUser";
import BookingStatus from "../../components/site/BookingStatus";
import BookingSummary from "../../components/site/BookingSummary";
import PrivacyPolicy from "../../components/site/PrivacyPolicy";
import TermsOfUse from "../../components/site/TermsOfUse";
import TermsOfService from "../../components/site/TermsOfService";
import SupportPolicy from "../../components/site/SupportPolicy";

// Common Components
import NotFound from "../../components/common/NotFound";

// Admin Components
import Admin from "../../components/admin/Admin";
import AdminHome from "../../components/admin/Home";
import ManageServices from "../../components/admin/ManageServices";
import ManageProfile from "../../components/admin/ManageProfile";
import UsersDetails from "../../components/admin/UsersDetails";
import Booking from "../../components/admin/Booking";
import ManageCourt from "../../components/admin/ManageCourt";
import ManageSports from "../../components/admin/ManageSports";
import ManageSlots from "../../components/admin/ManageSlots";
import ManageSlotsPricing from "../../components/admin/ManageSlotsPricing";

// User Components
import Profile from "../../components/user/Profile";
import MyBookings from "../../components/user/MyBookings";
import EditProfile from "../../components/user/EditProfile";
import Login from "../../components/admin/Login";

import BookNow from "../../components/admin/BookNow";

import { ADMIN_DATA, USER_ID } from "../../constants/DataConstants";

export default function Routers() {
  let adminAuth = ADMIN_DATA !== null;
  let userAuth = USER_ID !== null;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Site />}>
          <Route path="" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/courts" element={<Courts />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/support-policy" element={<SupportPolicy />} />
          {/* Turf Details */}
          <Route path="/turf-details/:id" element={<TurfDetails />} />
          <Route path="/booking-form" element={<BookingForm />} />
          <Route path="/new-user" element={<NewUser />} />
          <Route path="/verify-user" element={<VerifyUser />} />
          <Route path="/booking-summary" element={<BookingSummary />} />
          <Route path="/booking-status" element={<BookingStatus />} />
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/admin" element={<Admin />}>
          <Route path="" element={adminAuth ? <AdminHome /> : <Navigate to='/login' />} />
          <Route path="/admin/admin-dashboard" element={adminAuth ? <AdminHome /> : <Navigate to='/login' />} />
          <Route path="/admin/bookings" element={adminAuth ? <Booking /> : <Navigate to='/login' />} />
          <Route path="/admin/manage-courts" element={adminAuth ? <ManageCourt /> : <Navigate to='/login' />} />
          <Route path="/admin/manage-sports" element={adminAuth ? <ManageSports /> : <Navigate to='/login' />} />
          <Route path="/admin/manage-profile" element={adminAuth ? <ManageProfile /> : <Navigate to='/login' />} />
          <Route path="/admin/manage-services" element={adminAuth ? <ManageServices /> : <Navigate to='/login' />} />
          <Route path="/admin/manage-slots" element={adminAuth ? <ManageSlots /> : <Navigate to='/login' />} />
          <Route path="/admin/manage-slots-pricing" element={adminAuth ? <ManageSlotsPricing /> : <Navigate to='/login' />} />
          <Route path="/admin/user-details" element={adminAuth ? <UsersDetails /> : <Navigate to='/login' />} />
          <Route path="/admin/book-now" element={adminAuth ? <BookNow /> : <Navigate to='/login' />} />
        </Route>
        {/* User Profile routers */}
        <Route path="/user" element={<Profile />}>
          <Route path="/user/booking" element={userAuth ? <MyBookings /> : <Navigate to='/' />} />
          <Route path="/user/edit-profile" element={userAuth ? <EditProfile /> : <Navigate to='/' />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
