import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { getAdmin, updateAdmin, updateLogo } from '../../../services/ApiServices';
import AdminResetPass from '../../modals/AdminResetPass'
import { adminLogoUpdateSuccess, adminProfileUpdateSuccess, toastTimeStamp } from '../../../constants/ResponseConstants';
import ToastPopup from '../../modals/ToastPopup';
import { ImagePath } from '../../../services/ShortFunctions';

export default function ManageProfile() {
  useEffect(() => {
    window.scrollTo(0, 0); getAdminData()
  }, [])

  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const admin = JSON.parse(localStorage.getItem('adminLogin'));

  // Toast State Start
  const [showToast, setShowToast] = useState(false);
  const [tMessage, setTMessage] = useState();
  const [tVariant, setTVariant] = useState();
  // Toast State End

  const [adminData, setAdminData] = useState([]);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setImageFile(event.target.files[0]);
    }
  }

  const updateImage = async () => {
    const formData = new FormData();
    formData.append('org_logo', imageFile);
    formData.append('_method', 'PUT');

    // Toast Start
    setShowToast(true);
    try {
      const response = await updateLogo(admin.id, formData);
      setTMessage(response.message);
      setTVariant((response.message === adminLogoUpdateSuccess) ? 'success' : 'danger');
    } catch (error) {
      setTMessage(error);
      setTVariant('danger');
    }
    setTimeout(() => setShowToast(false), toastTimeStamp);
    // Toast End
    getAdminData();
    setImage();
  }

  // Modal Members & Functions Start
  const [show, setShow] = useState(false);
  const [mFor, setMFor] = useState();
  const handleClose = () => setShow(false);
  const handleShow = (m) => {
    setShow(true);
    setMFor(m);
  }
  // Modal Members & Functions End

  const getAdminData = async () => {
    const response = await getAdmin(admin.id).catch(console.error);
    const data = response.data;
    setAdminData(data);
    const formData = {
      'first_name': data.first_name,
      'last_name': data.last_name,
      'org_name': data.org_name,
      'org_logo': data.org_logo,
      'contact_number_one': data.contact_number_one,
      'contact_number_two': data.contact_number_two,
      'email_id': data.email_id,
      'address_line': data.address_line,
      'city': data.city,
      'district': data.district,
      'state': data.state,
      'pin_code': data.pin_code,
      'map': data.map,
      'facebook': data.facebook,
      'twitter': data.twitter,
      'instagram': data.instagram,
      'whatsapp': data.whatsapp,
    };
    formData && reset(formData);
  }

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('org_name', data.org_name);
    formData.append('org_logo', data.org_logo[0]);
    formData.append('contact_number_one', data.contact_number_one);
    formData.append('contact_number_two', data.contact_number_two);
    formData.append('email_id', data.email_id);
    formData.append('address_line', data.address_line);
    formData.append('city', data.city);
    formData.append('district', data.district);
    formData.append('state', data.state);
    formData.append('pin_code', data.pin_code);
    formData.append('map', data.map);
    formData.append('facebook', data.facebook);
    formData.append('twitter', data.twitter);
    formData.append('instagram', data.instagram);
    formData.append('whatsapp', data.whatsapp);
    formData.append('_method', 'PUT');

    // Toast Start
    setShowToast(true);
    try {
      const response = await updateAdmin(admin.id, formData);
      setTMessage(response.message);
      setTVariant((response.message === adminProfileUpdateSuccess) ? 'success' : 'danger');
    } catch (error) {
      setTMessage(error);
      setTVariant('danger');
    }
    setTimeout(() => setShowToast(false), toastTimeStamp);
    // Toast End
    getAdminData()
  }

  function getLogoImagePath() {
    return (adminData?.org_logo ? ImagePath(adminData.org_logo) : "/assets/img/logo.png");
  }

  return (
    <div>
      {showToast && <ToastPopup isShow={showToast} variant={tVariant} message={tMessage} />}
      <div className="office-information mb-lg-5">
        <div className="contact-form admin-form">
          <div className="user-img text-center mb-4">
            <label htmlFor="image">
              {/* <input type="file" id="image" style={{ display: 'none' }} {...register("org_logo")} onChange={onImageChange} /> */}
              <input type="file" id="image" style={{ display: 'none' }} onChange={onImageChange} />
              <img src={image || getLogoImagePath()} width='50' alt='' />
              <p><i className="fas fa-pencil-alt"></i></p>
            </label>
          </div>
          {image && <div className='text-center mb-3'>
            <button onClick={() => updateImage()} className="eg-btn btn--primary golf-btn mx-auto">Save
              <i className="bi bi-arrow-right"></i></button>
          </div>}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-6">
                <input type="text" placeholder="First Name" {...register("first_name")} />
              </div>
              <div className="col-lg-6">
                <input type="text" placeholder="Last Name" {...register('last_name')} />
              </div>
              <div className="col-lg-12 mb-3">
                <input type="text" className='mb-0' placeholder="Organization Name" {...register('org_name', { required: true })} />
                {errors.org_name && <span className='text-danger fw-bold'>Organisation name is required</span>}
              </div>
              <div className="col-lg-6 mb-3">
                <input type="number" className='mb-0' placeholder="+91 000 000 0000" {...register('contact_number_one', { required: true })} />
                {errors.contact_number_one && <span className='text-danger fw-bold'>Contact Number is required</span>}
              </div>
              <div className="col-lg-6 mb-3">
                <input type="number" className='mb-0' placeholder="+91 000 000 0000" {...register('contact_number_two')} />
                {errors.contact_number_two && <span className='text-danger fw-bold'>Contact Number</span>}
              </div>
              <div className="col-lg-12">
                <input type="email" placeholder="admin@email.com" {...register('email_id')} />
              </div>
              <div className="col-lg-9">
                <input type="text" placeholder="Your Address" {...register('address_line')} />
              </div>
              <div className="col-lg-3">
                <input type="text" placeholder="City" {...register('city')} />
              </div>
              <div className="col-lg-4">
                <input type="text" placeholder="District" {...register('district')} />
              </div>
              <div className="col-lg-4">
                <input type="text" placeholder="State" {...register('state')} />
              </div>
              <div className="col-lg-4">
                <input type="text" className='mb-0' placeholder="Pincode" {...register('pin_code', { required: true })} />
                {errors.pincode && <span className='text-danger fw-bold'>Pin Code is required</span>}
              </div>
              <div className="col-lg-12">
                <textarea type="text" className='w-100 mb-2' placeholder="Map Embed Code" {...register('map')} />
              </div>
              <hr />
              <div className="col-lg-6">
                <input type="text" placeholder="Facebook URL" {...register("facebook")} />
              </div>
              <div className="col-lg-6">
                <input type="text" placeholder="Twitter URL" {...register('twitter')} />
              </div>
              <div className="col-lg-6">
                <input type="text" placeholder="Instagram URL" {...register("instagram")} />
              </div>
              <div className="col-lg-6">
                <input type="text" placeholder="WhatsApp URL" {...register('whatsapp')} />
              </div>
            </div>
            <div className="button--wrap">
              <button type="submit" className="eg-btn btn--primary golf-btn mx-auto">Save
                <i className="bi bi-arrow-right"></i></button>
            </div>
          </form> <hr />
          <div className="button--wrap">
            <button className="eg-btn btn--primary golf-btn mx-auto py-2 ps-4 pe-2" onClick={() => handleShow('admin')}>
              Reset Password <i className="bi bi-arrow-right"></i></button>
          </div>
        </div>
      </div>
      {show && <AdminResetPass show={show} handleClose={handleClose} mFor={mFor} />}
    </div>
  )
}
