import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getSingleUser } from "../../../services/ApiServices";
import { USER_ID } from "../../../constants/DataConstants";

export default function UserSideBar() {
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); getUserData()
  }, []);

  const { first_name, last_name, contact_number, email_id } = user;

  const getUserData = async () => {
    const response = await getSingleUser(USER_ID).catch(console.error);
    setUser(response.data);
  }

  const onLogout = () => {
    localStorage.removeItem('loggedInUser');
    navigate('/');
    window.location.reload();
  }

  const location = useLocation();

  const sidebarLinks = [
    { to: "/user/booking", icon: "fas fa-bookmark", text: "My Bookings" },
    { to: "/user/edit-profile", icon: "fas fa-user-edit", text: "Edit Profile" }
  ];

  const checkActive = (link) => {
    return link.to === location.pathname;
  };

  return (
    <div>
      <div className="blog-sidebar text-center">
        <div className="sidebar-widget">
          <h4>Welcome {((first_name && last_name) && (first_name + ' ' + last_name)) || 'Player'}!</h4>
          <div className="user-img">
            <img src="/assets/img/logo2.png" alt="" />
          </div>
          <div className="user-name mt-3">
            <span>{((first_name && last_name) && (first_name + ' ' + last_name)) || 'Player'}</span>
          </div>
          <div className="user-mobile">
            <span>{contact_number && `+91 ${contact_number}`}</span>
          </div>
          <div className="user-email">
            <span>{email_id && email_id}</span>
          </div>
          <hr />
          <ul className="ps-0">
            {sidebarLinks.map((link, index) => (
              <li key={index}>
                <NavLink to={link.to} activeClassName="active" isActive={checkActive(link)}>
                  <i className={`${link.icon} me-3`} />{link.text}</NavLink>
              </li>
            ))}
          </ul>
          <div className={`blog-post mb-1 active`}>
            <button onClick={() => onLogout()} className="w-100 p-2">
              <i className='fa-solid fa-right-from-bracket text-dark me-3' /> Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
