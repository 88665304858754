import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../common/BreadCrumb";
import { Link, useParams } from "react-router-dom";
import { getAdmin, getSingleCourt } from "../../../services/ApiServices";
import Loader from "../../common/Loader";
import { ImagePath, StringToHTML } from '../../../services/ShortFunctions';

export default function TurfDetails() {

  useEffect(() => {
    window.scrollTo(0, 0);
    loadSingleCourt();
    getAdminData();
  }, []);

  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [court, setCourt] = useState([]);
  const [orgData, setOrgData] = useState([]);

  const loadSingleCourt = async () => {
    setIsLoading(true);
    const response = await getSingleCourt(id).catch(console.error);
    setCourt(response.data);
    setIsLoading(false);
  };

  const formattedTime = (time) => {
    if (time) {
      const [hours, minutes] = time.split(":");
      const timeObj = new Date();
      timeObj.setHours(hours);
      timeObj.setMinutes(minutes);
      const ampm = timeObj.getHours() >= 12 ? "PM" : "AM";
      let hours12 = timeObj.getHours() % 12;
      hours12 = hours12 ? hours12 : 12; // convert 0 to 12
      return `${hours12}:${minutes} ${ampm}`;
    }
  };

  const getAdminData = async () => {
    const response = await getAdmin(1).catch(console.error);
    setOrgData(response.data);
    console.log("response.data: " + response.data);
  }

  return (
    <div>
      {isLoading && <Loader />}
      <BreadCrumb name="Turf Details" />
      <div className="latest-blog sec-mar">
        {court && (
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="blog-details">
                  <div className="thumbnail-preview">
                    <img src={court.image_path && ImagePath(court.image_path)} alt="" />
                  </div>
                  <h3 className="mt-5">About Venue</h3>
                  <p>{court.description}</p>
                </div>
                <div className="sidebar-widget mt-5">
                  <Link to={'/services'}><h4 className="mb-0">Amenities</h4></Link>
                  {/* <div className="row">
                      <div className="col-4">
                        <i className="bi bi-check2-circle me-2"></i>{" "}
                        <span>First Aid</span>
                      </div>
                      <div className="col-4">
                        <i className="bi bi-check2-circle me-2"></i>{" "}
                        <span>Change Room</span>
                      </div>
                      <div className="col-4">
                        <i className="bi bi-check2-circle me-2"></i>{" "}
                        <span>Drinking Water</span>
                      </div>
                    </div> */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="blog-sidebar">
                  <div className="button--wrap button--wrap-two mt-0">
                    <Link
                      onClick={() => localStorage.setItem("courtId", id)}
                      to="/booking-form"
                      className="text-light eg-btn btn--primary golf-btn mx-auto"
                      style={{ zIndex: "auto" }}
                    >{" "}Book Now
                    </Link>
                  </div>
                  <div className="sidebar-widget mt-4">
                    <h4>Timings</h4>
                    <p>
                      {/* {formattedTime("13:30:45")} - {formattedTime("08:30:45")} */}
                      {formattedTime(court?.open_time)} - {" "}{formattedTime(court?.close_time)}
                    </p>
                  </div>
                  <div className="sidebar-widget">
                    <h4>Location</h4>
                    <p className="fw-bold">{orgData.address_line}{', '}{orgData.city}{', '}{orgData.city}{', '}{orgData.district}{', '}{orgData.state}{' - '}{orgData.pin_code}</p>
                    {StringToHTML(orgData.map)}
                  </div>

                  <div className="sidebar-widget">
                    <div className="social-follow">
                      <h4>Follow On</h4>
                      <ul className="social-share">
                        <li><a href={orgData.facebook}><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href={orgData.facebook}><i className="fab fa-twitter"></i></a></li>
                        <li><a href={orgData.facebook}><i className="fab fa-instagram"></i></a></li>
                        <li><a href={orgData.facebook}><i className="fab fa-whatsapp"></i></a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="widget-banner" style={{ height: 250 }}>
                    <img src="assets/img/widget-banner.jpg" alt="" />
                    <div className="banner-inner">
                      <h2>Any Question Call Now.</h2>
                      <a href={`tel:${orgData.contact_number_one}`}>{orgData.contact_number_one}</a><br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
