import React, { useEffect, useState } from "react";
import ToastPopup from "../ToastPopup";
import { Modal } from "react-bootstrap";
import { ImagePath } from "../../../services/ShortFunctions";

export default function ServicesModal(props) {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={ImagePath(props.data.image_path)} alt="MyTurf" />
          </div>
          <div className="single-post">
            <div className="post-thumbnail">
              <div className="news-cnt">
                <h3>
                  <a href="javascript:">{props.data.name}</a>
                </h3>
              </div>
              <p>{props.data.description}</p>
            </div>
          </div>
        </Modal.Body>

        {/* <Modal.Footer>
          <div className="button--wrap">
            <button type="submit" className="eg-btn btn--primary golf-btn mx-auto p-2 px-3">Okay <i className="bi bi-arrow-right"></i></button>
          </div>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
