import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import { toastTimeStamp } from '../../../constants/ResponseConstants';
import { StringToHTML } from '../../../services/ShortFunctions';

export default function ToastPopup(props) {
  const { variant, message, isShow } = props;
  const [show, setShow] = useState(isShow);

  useEffect(() => { setShow(isShow) }, [isShow]);

  return (
    <ToastContainer position="bottom-end" className="p-3" style={{ zIndex: 100 }}>
      <Toast onClose={() => setShow(false)} show={show} delay={toastTimeStamp} autohide bg={variant}>
        <Toast.Header>
          <img src="/assets/img/logo2.png" className="rounded me-2" width='10%' alt="" />
          <strong className="me-auto">BnB Turf</strong>
          <small>Now</small>
        </Toast.Header>
        <Toast.Body className={(variant === 'success' || variant === 'danger') && 'text-white'}>
          {StringToHTML(message)}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}