import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../common/BreadCrumb";
import ServicesModal from "../../modals/ServicesModal";
import { getServices } from "../../../services/ApiServices";
import { ImagePath } from "../../../services/ShortFunctions";

export default function Services() {
  // const servicesData = [
  //   { stitle: 'Snooker Table', imgPath: '/assets/img/services/snooker-table.png', descr: 'service description' },
  //   { stitle: 'Table Tennis', imgPath: '/assets/img/services/table-tennis.png', descr: 'service description' },
  //   { stitle: 'CCTV Surveillance', imgPath: '/assets/img/services/cctv.png', descr: 'service description' },
  //   { stitle: 'Drinking Water', imgPath: '/assets/img/services/drinking-water.png', descr: 'service description' },
  //   { stitle: 'Changing Room', imgPath: '/assets/img/services/changing-room.png', descr: 'service description' },
  //   { stitle: 'Waiting Area', imgPath: '/assets/img/services/waiting-area.png', descr: 'service description' },
  //   { stitle: 'Car & Bike Parking', imgPath: '/assets/img/services/parking.png', descr: 'service description' },
  //   { stitle: 'Washrooms', imgPath: '/assets/img/services/washroom.png', descr: 'service description' },
  // ]

  const [services, setServices] = useState([]);
  const [oneServices, setOneServices] = useState([]);

  // Modal Members & Functions Start
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setOneServices(data);
  };
  // Modal Members & Functions End

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllServices();
  }, []);

  const getAllServices = async () => {
    const response = await getServices().catch(console.error);
    setServices(response.data);
  };

  return (
    <div>
      <BreadCrumb name="Services & Facilities" />
      <div className="latest-blog grid sec-mar py-5 mt-0">
        <div className="container">
          <div className="row g-4">
            {services.map((item, i) => (
              <div className="col-md-6 col-lg-4" key={i}>
                <div className="single-post">
                  <div className="post-thumbnail">
                    <img src={ImagePath(item.image_path)} alt="MyTurf" />
                  </div>
                  <div className="news-cnt">
                    <h3>
                      <a href="javascript:">{item.name}</a>
                    </h3>
                    <div className="button--wrap button--wrap-two">
                      <button
                        className="eg-btn btn--primary golf-btn mx-auto"
                        type="button"
                        onClick={() => handleShow(item)}
                        style={{ zIndex: "auto" }}
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {show && (
        <ServicesModal
          data={oneServices}
          handleClose={handleClose}
          show={show}
        />
      )}
    </div>
  );
}
