import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAdmin } from '../../../services/ApiServices';

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0); getAdminData();
  }, []);

  const [orgData, setOrgData] = useState([]);

  const getAdminData = async () => {
    const response = await getAdmin(1).catch(console.error);
    setOrgData(response.data);
  }

  return (
    <div className='container my-5'>
      <h4>Privacy Policy for BnB Turf</h4>
      <p>This Privacy Policy outlines how BnB Turf collects, uses, and safeguards personal information provided by users of our website. We prioritize the protection and privacy of your personal information and are committed to being transparent about the data we collect and how we use it. Please read this policy carefully to understand our practices regarding your personal information.</p>
      <ol type='1'>
        <li className='fw-bold h5'>Information We Collect:</li>
        <p>We may collect various types of personal information including, but not limited to:</p>
        <ul>
          <li>Contact information (e.g., name, email address, phone number)</li>
          <li>Payment information (e.g., credit card details)</li>
          <li>Demographic information (e.g., age, gender, location)</li>
          <li>Preferences and interests</li>
          <li>Usage data (e.g., IP address, browser type, access times)</li>
          <li>Any other information you provide to us voluntarily</li>
        </ul>
        <li className='fw-bold h5 mt-4'>Use of Information:</li>
        <p>We use the collected information for the following purposes:</p>
        <ul>
          <li>Providing and improving our services</li>
          <li>Responding to inquiries and providing customer support</li>
          <li>Processing bookings and payments</li>
          <li>Personalizing user experience</li>
          <li>Sending promotional offers, newsletters, or other marketing materials</li>
          <li>Analyzing website usage and trends to enhance our services</li>
          <li>Securing and protecting our website and users' information</li>
        </ul>
        <li className='fw-bold h5 mt-4'>Sharing of Information:</li>
        <p>We may share your personal information with third parties only in the following circumstances:</p>
        <ul>
          <li>Trusted service providers and partners who help us operate our website and services (e.g., payment processors, hosting providers)</li>
          <li>Authorities or legal entities when required by law or to protect our rights, safety, or property</li>
          <li>Third-party affiliates or marketing partners for promotional purposes (subject to your consent)</li>
        </ul>
        <li className='fw-bold h5 mt-4'>Cookies and Tracking Technologies:</li>
        <p>We use cookies and similar tracking technologies to enhance your browsing experience, analyze website traffic, and collect information about user behavior. You can choose to disable cookies in your browser settings, but it may affect certain functionalities of our website.</p>
        <li className='fw-bold h5 mt-4'>Data Security:</li>
        <p>We employ industry-standard security measures to protect the confidentiality, integrity, and availability of your personal information. However, please note that no method of transmission over the internet or electronic storage is 100% secure.</p>
        <li className='fw-bold h5 mt-4'>Links to Third-Party Websites:</li>
        <p>Our website may contain links to third-party websites. We do not have control over the privacy practices of these websites and are not responsible for their content. We encourage you to review the privacy policies of any third-party websites you visit.</p>
        <li className='fw-bold h5 mt-4'>Children's Privacy:</li>
        <p>Our services are not intended for individuals under the age of 18. We do not knowingly collect or store personal information from children. If we become aware of any personal information collected from a child, we will promptly delete it.</p>
        <li className='fw-bold h5 mt-4'>Your Rights:</li>
        <p>You have the right to access, update, and delete your personal information. If you wish to exercise these rights or have any concerns regarding your personal information, please contact us using the information provided below.</p>
        <li className='fw-bold h5 mt-4'>Changes to this Privacy Policy:</li>
        <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on our website. We encourage you to review this Privacy Policy periodically.</p>
        <li className='fw-bold h5 mt-4'>Contact Information:</li>
        <p>If you have any questions, concerns, or requests relating to this Privacy Policy, please contact us at:</p>
        {orgData && <>
          Email ID.: <Link to={`mailto:${orgData.email_id}`} className='text-dark fw-bold'>{orgData.email_id}</Link><br />
          <div className='d-flex'>Phone No.:
            <div className='ms-1'>
              <Link to={`tel:${orgData.contact_number_one}`} className='text-dark fw-bold'>{orgData.contact_number_one}</Link><br />
              <Link to={`tel:${orgData.contact_number_two}`} className='text-dark fw-bold'>{orgData.contact_number_two}</Link>
            </div>
          </div>
          <div className='d-flex'>Address:<div className='text-dark fw-bold ms-1'> {orgData.address_line}{', '}{orgData.city}{', '}{orgData.city}{', '}{orgData.district}{', '}{orgData.state}{' - '}{orgData.pin_code}</div></div>
        </>}
        <p>By using the BnB Turf website, you signify your acceptance of this Privacy Policy.</p>
      </ol>
    </div>
  )
}