import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  createTimeSlotPricing,
  updateTimeSlotPricing,
  getCourts,
  getTimeSlots,
} from "../../../services/ApiServices";
import {
  timeSlotPricingAddedSuccess,
  timeSlotPricingUpdatedSuccess,
  toastTimeStamp,
} from "../../../constants/ResponseConstants";
import ToastPopup from "../ToastPopup";
import ReactDatePicker from "react-datepicker";
import Loader from "../../common/Loader";

function ManageTimeSlotPricing(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [specialDayDate, setSpecialDayDate] = useState(new Date());
  const [courts, setCourts] = useState();
  const [timeSlots, setTimeSlots] = useState();
  const [isStatusActive, setIsStatusActive] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOffer, setIsOffer] = useState(false);

  // Toast State Start
  const [showToast, setShowToast] = useState(false);
  const [tMessage, setTMessage] = useState();
  const [tVariant, setTVariant] = useState();
  // Toast State End

  useEffect(() => {
    window.scrollTo(0, 0);
    props.mFor !== "Add" && reset(props.mFor);
    loadCourts();
    loadTimeSlots();
  }, []);

  const onSubmit = async (data) => {
    const {
      CourtID,
      TimeSlotID,
      PriceVariation,
      DayType,
      OfferDescription,
      OfferImagePath,
      isActive,
    } = data;
    const formData = new FormData();
    formData.append(
      "Id",
      props.mFor.id !== (null || undefined) ? props.mFor.id : 1
    );
    formData.append("CourtID", CourtID);
    formData.append("TimeSlotID", TimeSlotID);
    formData.append("PriceVariation", PriceVariation);
    formData.append("DayType", DayType);

    if (isOffer) {
      formData.append("specialDayDate", getFormattedDate());
      formData.append("OfferDescription", OfferDescription);
      if (typeof OfferImagePath !== "string") {
        formData.append(
          "OfferImagePath",
          OfferImagePath[0],
          OfferImagePath[0].name
        );
      }
    } else {
      formData.append("specialDayDate", null);
      formData.append("OfferDescription", null);
      if (typeof OfferImagePath !== "string") {
        formData.append("OfferImagePath", null);
      }
    }
    formData.append("isActive", isActive ? 1 : 0);
    if (props.mFor !== "Add") {
      formData.append("_method", "PUT");
    }
    props.handleClose();
    setShowToast(true);
    try {
      const response = await (props.mFor === "Add"
        ? createTimeSlotPricing(formData)
        : updateTimeSlotPricing(formData, props.mFor.id));
      // Toast Start
      setTMessage(response.message);
      setTVariant(
        response.message === timeSlotPricingUpdatedSuccess ||
          response.message === timeSlotPricingAddedSuccess
          ? "success"
          : "danger"
      );
      // Toast End
    } catch (error) {
      setTMessage(error);
      setTVariant("danger");
    }
    setTimeout(() => setShowToast(false), toastTimeStamp);
  };

  const loadCourts = async () => {
    setIsLoading(true);
    const response = await getCourts().catch(console.error);
    setCourts(response.data);
    setIsLoading(false);
  };

  const loadTimeSlots = async () => {
    setIsLoading(true);
    const response = await getTimeSlots().catch(console.error);
    setTimeSlots(response.data);
    setIsLoading(false);
  };

  const getFormattedDate = () => {
    const day = ("0" + specialDayDate.getDate()).slice(-2); // Get day of the month
    const month = ("0" + (specialDayDate.getMonth() + 1)).slice(-2); // Get month (adding 1 since January is 0)
    const year = specialDayDate.getFullYear(); // Get year
    return `${year}-${month}-${day}`; // Format date as 'YYYY-MM-DD'
  };

  const isOfferFn = () => {
    setIsOffer(!isOffer);
  };

  return (
    <>
      {isLoading && <Loader />}
      {showToast && (
        <ToastPopup isShow={showToast} variant={tVariant} message={tMessage} />
      )}
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        dialogClassName="modal-90w"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.mFor === "Add" ? "Add" : "Edit"} Time Slot Pricing
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
              <label
                className="col-sm-4 col-form-label"
                style={{ fontSize: 14 }}
              >
                Select Court <span className="text-danger">*</span>:
              </label>
              <div className="col-sm-8">
                <select
                  {...register("CourtID", { required: "Select Court" })}
                  className="form-select mb-0"
                >
                  {courts &&
                    courts.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.court_name}
                      </option>
                    ))}
                </select>
              </div>
              {errors.CourtID && (
                <span className="text-danger fw-bold small">
                  Select day type.
                </span>
              )}
            </div>
            <div className="row mb-3">
              <label
                className="col-sm-4 col-form-label"
                style={{ fontSize: 14 }}
              >
                Select Time Slot<span className="text-danger">*</span>:
              </label>
              <div className="col-sm-8">
                <select
                  {...register("TimeSlotID", { required: "Select Time Slot" })}
                  className="form-select mb-0"
                >
                  {timeSlots &&
                    timeSlots.map((item, i) => (
                      <option key={i} value={item.TimeSlotID}>
                        {item.SlotName}
                      </option>
                    ))}
                </select>
              </div>
              {errors.TimeSlotID && (
                <span className="text-danger fw-bold small">
                  Select day type.
                </span>
              )}
            </div>
            <div className="row mb-3">
              <label
                className="col-sm-4 col-form-label"
                style={{ fontSize: 14 }}
              >
                Price Variation<span className="text-danger">*</span>:
              </label>
              <div className="col-sm-8">
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    {...register("PriceVariation", { required: true })}
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              {errors.PriceVariation && (
                <span className="text-danger small fw-medium">
                  Rate per hour is required
                </span>
              )}
            </div>
            <div className="row mb-3">
              <label
                className="col-sm-4 col-form-label"
                style={{ fontSize: 14 }}
              >
                Select Day <span className="text-danger">*</span>:
              </label>
              <div className="col-sm-8">
                <select
                  {...register("DayType", { required: "Select Day Type" })}
                  className="form-select mb-0"
                >
                  <option value="S">Special Day</option>
                  <option value="W">Weekend Day</option>
                  <option value="N">Normal Day</option>
                </select>
              </div>
              {errors.DayType && (
                <span className="text-danger fw-bold small">
                  Select day type.
                </span>
              )}
            </div>
            <div className="row mb-3">
              <label
                className="col-sm-4 col-form-label"
                style={{ fontSize: 14 }}
              >
                is this offer? <span className="text-danger">*</span>:
              </label>
              <div className="col-sm-8 form-check form-switch">
                <label className="form-label mb-0" style={{ fontSize: 14 }}>
                  {isOffer ? "Yes" : "No"}
                </label>
                <input
                  className="form-check-input bg-success border-success"
                  type="checkbox"
                  role="switch"
                  onChange={() => isOfferFn()}
                />
              </div>
            </div>
            {isOffer && (
              <>
                <div className="row mb-3">
                  <label
                    className="col-sm-4 col-form-label"
                    style={{ fontSize: 14 }}
                  >
                    Select Date <span className="text-danger">*</span>:
                  </label>
                  <div className="col-sm-8">
                    <ReactDatePicker
                      selected={specialDayDate}
                      minDate={new Date()}
                      dateFormat={"yyyy-MM-dd"}
                      className="mb-0 z-3"
                      onChange={(date) => setSpecialDayDate(date)}
                    />
                    {errors.SpecialDayDate && (
                      <p className="text-warning small">* Select Date</p>
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <label
                    className="form-label col-sm-4"
                    style={{ fontSize: 14 }}
                  >
                    Offer Details
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Offer Description"
                      {...register("OfferDescription", { required: true })}
                    />
                  </div>
                  {errors.OfferDescription && (
                    <span className="text-danger small fw-medium">
                      Description is required
                    </span>
                  )}
                </div>
                <div className="row mb-3">
                  <label
                    className="col-sm-4 col-form-label"
                    style={{ fontSize: 14 }}
                  >
                    Offer Image
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="file"
                      className="form-control col-sm-8"
                      accept="image/*"
                      {...register("OfferImagePath")}
                    />
                  </div>
                  {errors.OfferImagePath && (
                    <span className="text-danger small fw-medium">
                      Image is required
                    </span>
                  )}
                </div>
              </>
            )}
            <div className="row mb-3">
              <label
                className="col-sm-4 col-form-label"
                style={{ fontSize: 14 }}
              >
                Status <span className="text-danger">*</span>:
              </label>
              <div className="col-sm-8 form-check form-switch">
                <label className="form-label mb-0" style={{ fontSize: 14 }}>
                  {isStatusActive ? "Active" : "InActive"}
                </label>
                <input
                  className="form-check-input bg-success border-success"
                  type="checkbox"
                  role="switch"
                  {...register("isActive")}
                  onChange={() => setIsStatusActive(!isStatusActive)}
                />
              </div>
            </div>

            <Modal.Footer>
              <div
                className="button--wrap button--wrap-two mt-0"
                onClick={props.handleClose}
              >
                <button className="eg-btn btn--primary golf-btn mx-auto z-0">
                  <Link className="text-light" to="">
                    Close
                  </Link>
                </button>
              </div>
              <div className="button--wrap button--wrap-two mt-0">
                <button
                  className="eg-btn btn--primary golf-btn mx-auto z-0"
                  type="submit"
                >
                  <span className="text-light">
                    {props.mFor === "Add" ? "Add" : "Save"}
                  </span>
                </button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ManageTimeSlotPricing;
