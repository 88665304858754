import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { createTimeSlot, deleteTimeSlot, updateTimeSlot, getTimeSlots, getSingleTimeSlot, getCourts } from '../../../services/ApiServices';
import { timeSlotAddedSuccess, timeSlotUpdatedSuccess, toastTimeStamp, timeSlotDeleteSuccess } from '../../../constants/ResponseConstants';
import ToastPopup from '../../modals/ToastPopup';
import Loader from '../../common/Loader';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { ImagePath, StringToHTML } from '../../../services/ShortFunctions';

function ManageSlots() {
  useEffect(() => {
    window.scrollTo(0, 0); loadTimeSlots();loadCourts();
  }, []);

  const loadTimeSlots = async () => {
    setIsLoading(true);
    const response = await getTimeSlots().catch(console.error);
    setTimeSlots(response.data);
    setIsLoading(false);
    let id = localStorage.getItem("courtId");
    reset({ court_id: id });
  };
  
  const [courts, setCourts] = useState();
  const [allTimeSlots, setAllTimeSlots] = useState([]);
  const [isAddTimeSlot, setIsAddTimeSlot] = useState();
  const [disableBtn, setDisableBtn] = useState()
  const [timeSlots, setTimeSlots] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // Toast State Start
  const [showToast, setShowToast] = useState(false);
  const [tMessage, setTMessage] = useState();
  const [tVariant, setTVariant] = useState();
  // Toast State End 
  const loadCourts = async () => {
    setIsLoading(true);
    const response = await getCourts().catch(console.error);
    setCourts(response.data);
    setIsLoading(false);
    let id = localStorage.getItem("courtId");
    reset({ court_id: id });
  };

  // Handle onSubmit TimeSlot Start
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const onSubmit = async (data) => await (isAddTimeSlot ? onAddTimeSlot(data) : onUpdateTimeSlot(data));
  // Handle onSubmit TimeSlot Start

  // Handle Add TimeSlot Start
  const onAddTimeSlot = async (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('Id', 1);
    formData.append('CourtID', data.CourtID);
    formData.append('SlotName', data.SlotName);
    formData.append('start_Time', data.start_Time);
    formData.append('End_Time', data.End_Time);
    const response = await createTimeSlot(formData).catch(console.error);
    setIsLoading(false);
    // Toast Start
    setShowToast(true);
    if (response?.message === timeSlotAddedSuccess) {
      setTMessage('<b>' + data.SlotName + '</b> TimeSlot Inserted Successfully.');
      setTVariant('success');
      setIsAddTimeSlot(false);
    } else {
      // setTMessage(response.message);
      setTMessage('Something went wrong.');
      setTVariant('danger');
    }
    setTimeout(() => setShowToast(false), toastTimeStamp);
    // Toast End
    loadTimeSlots();
  };
  // Handle Add TimeSlot End

  // Handle Update TimeSlot Start
  const onUpdateTimeSlot = async (data) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('image_path', data.image_path[0], data.image_path[0].name);
    formData.append('_method', 'PUT');
    const response = await updateTimeSlot(formData, data.id).catch(console.error);
    // Toast Start
    setShowToast(true);
    if (response.message === timeSlotUpdatedSuccess) {
      setTMessage('<b>' + data.name + '</b>. ' + response.message);
      setTVariant('success');
    } else {
      setTMessage(response.message);
      setTVariant('danger');
    }
    setTimeout(() => setShowToast(false), toastTimeStamp);
    // Toast End
    loadTimeSlots();
  };
  // Handle Update TimeSlot End

  // Handle Edit Start
  const handleEdit = async (id) => {
    const singleTimeSlot = await getSingleTimeSlot(id).catch(console.error);
    
    reset(singleTimeSlot.data);
  }
  // Handle Edit End

  //Manage Court Enable/Disable Status Start
  const handleDelete = async (id, name) => {
    confirmAlert({
      title: StringToHTML('<h3>Confirm to delete</h3>'),
      message: StringToHTML(`Are you sure do you want to delete <span style='font-weight: bold; color: red;'>${name}</span> TimeSlot.`),
      buttons: [
        {
          label: 'Yes', onClick: async () => {
            setIsLoading(true);
            const response = await deleteTimeSlot(id).catch(console.error);
            loadTimeSlots();
            setIsLoading(false);
            // Toast Start
            setShowToast(true);
            if (response.message === timeSlotDeleteSuccess) {
              setTMessage('<b>' + name + '</b> ' + response.message);
              setTVariant('success');
            } else {
              setTMessage(response.message);
              setTVariant('danger');
            }
            setTimeout(() => setShowToast(false), toastTimeStamp);
            // Toast End}
          }
        },
        { label: 'No' }
      ]
    });
  };
  //Manage Court Enable/Disable Status End

  return (
    <>
      {isLoading && <Loader />}
      {showToast && <ToastPopup isShow={showToast} variant={tVariant} message={tMessage} />}
      <div className='manage-TimeSlots'>
        <h4>Manage TimeSlots</h4>
        <div className="row">
          <div className="col-lg-7 col-md-12 border-end py-4">
            <div className="table-responsive">
              <table className="table table-responsive table-secondary-subtle table-striped">
                <thead>
                  <tr>
                    <th scope='col'>Turf Name</th>
                    <th scope="col">Slot Name</th>
                    <th scope="col">Start Time</th>
                    <th scope="col">End Time</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {timeSlots && timeSlots.map((item, i) => (
                    <tr key={i}>
                      <td>{item.court_name}</td>
                      <td>{item.SlotName}</td>
                      <td>{item.start_Time}</td>
                      <td>{item.End_Time}</td>
                      <td className='d-flex'>
                        {/* <Link className="icon-edit me-0" onClick={() => handleEdit(item.id)} >
                          <i className="fas fa-pencil-alt ms-2"></i>
                        </Link> */}
                        <Link className="icon-delete" onClick={() => handleDelete(item.ID, item.SlotName)} >
                          <i className="fas fa-trash-alt ms-2"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-5 col-md-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label htmlFor="TimeSlotCourt" className="form-label">Select Turf <span className='text-danger'>*</span>:</label>
                <select {...register("CourtID", { required: 'Select Turf' })} className="form-select mb-0 fw-bold">
                  {courts && courts.map((item, i) => <option key={i} value={item.id}>{item.court_name}</option>)}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="TimeSlotTitle" className="form-label">Slot Name</label>
                <input className="form-control" onChange={() => setDisableBtn(false)} placeholder="Slot Name" {...register("SlotName", { required: true })} />
                {errors.SlotName && <span className='text-danger small fw-bold'>* This field is required</span>}
              </div>
              <div className='row'>
                <div className="mb-3 col-6">
                  <label htmlFor="TimeSlotText" className="form-label">Slot Start Time</label>
                  <input className="form-control" type='time' onChange={() => setDisableBtn(false)} {...register("start_Time")}></input>
                {errors.start_Time && <span className='text-danger small fw-bold'>* This field is required</span>}
                </div>
                <div className="mb-3 col-6">
                  <label htmlFor="TimeSlotText" className="form-label">Slot End Time</label>
                  <input className="form-control" type='time' onChange={() => setDisableBtn(false)} {...register("End_Time")}></input>
                {errors.End_Time && <span className='text-danger small fw-bold'>* This field is required</span>}
                </div>
              </div>
              <div className="row mt-4">
                {/* <div className="col-4">
                  <div className="button--wrap">
                    <button type="submit" className="eg-btn btn--primary golf-btn mx-auto px-3 py-2" disabled={disableBtn}>Update</button>
                  </div>
                </div> */}
                <div className="col-8">
                  <div className="button--wrap">
                    <button type="submit" className="eg-btn btn--primary golf-btn mx-auto px-3 py-2" onClick={() => setIsAddTimeSlot(true)}>Add New Slot</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ManageSlots