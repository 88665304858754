import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSports, getCourts, getPrice } from "../../../services/ApiServices";
// import Loader from "../../../services/Loader";
import Loader from "../../common/Loader";
import { useForm } from "react-hook-form";
import { USER_ID } from "../../../constants/DataConstants";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toastTimeStamp } from "../../../constants/ResponseConstants";
import ToastPopup from '../../modals/ToastPopup';

export default function BookingForm() {
  useEffect(() => {
    window.scrollTo(0, 0);
    loadSports();
    loadCourts();
  }, []);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sports, setSports] = useState(false);
  const [courts, setCourts] = useState();
  const [duration, setDuration] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  // const userAuth = () => !!JSON.parse(localStorage.getItem("BnBTurfUserLogin"));
  // const userAuth = () => false;

  // Toast State Start
  const [showToast, setShowToast] = useState(false);
  const [tMessage, setTMessage] = useState();
  const [tVariant, setTVariant] = useState();
  // Toast State End

  const loadSports = async () => {
    setIsLoading(true);
    const response = await getSports().catch(console.error);
    setSports(response.data);
    setIsLoading(false);
  };
  const loadCourts = async () => {
    setIsLoading(true);
    const response = await getCourts().catch(console.error);
    setCourts(response.data);
    setIsLoading(false);
    let id = localStorage.getItem("courtId");
    reset({ court_id: id });
  };

  const manageDuration = (flag) => setDuration(flag === "D" && duration > 1 ? duration - 1 : duration + 1);

  const onSubmit = async (bData) => {
    let sTime = (startTime.getHours() < 10 ? '0' + startTime.getHours() : startTime.getHours()) + ':' + (startTime.getMinutes() < 10 ? '0' + startTime.getMinutes() : startTime.getMinutes());
    const data = { 'booking_date': getFormattedDate(), 'start_time': sTime, ...bData };

    data.end_time = calculateEndTime(data);
    // getPrice code start
    const getPriceData = {
      BKStart_time: data.start_time + ':00',
      BKEnd_Time: data.end_time + ':00',
      CourtId: data.court_id
    }
    try {
      const response = await getPrice(getPriceData);
      let finalTotalPrice = 0, baseTotalPrice = 0;
      for (let index = 0; index < response.data.length; index++) {
        finalTotalPrice += Number(response.data[index]['FinalPrice']);
        baseTotalPrice += Number(response.data[index]['BasePrice']);
      }
      if (finalTotalPrice !== 0 && baseTotalPrice !== 0) {
        const newData = { baseTotalPrice, finalTotalPrice, ...data }
        localStorage.setItem("BookingDetails", JSON.stringify(newData));
        USER_ID === null ? navigate("/new-user") : navigate("/booking-summary");
      }
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:83 ~ onSubmit ~ error: ", error)
      if (error.response) {
        // Toast Start
        setShowToast(true);
        setTMessage('<b>' + error.response.data.message.errorInfo[2] + '</b>.');
        setTVariant('danger');
        setTimeout(() => setShowToast(false), toastTimeStamp);
        // Toast End
      } else if (error.request) {
        console.error('Network Error: ', error.request);
      } else {
        console.error('Error: ', error.message);
      }
    }
    // getPrice code end
  }

  const calculateEndTime = (data) => {
    const padZero = (num) => (num < 10 ? '0' : '') + num;
    const timeParts = (duration + ':00').split(":");
    const startParts = data.start_time.split(':');
    const totalMins = parseInt(startParts[0]) * 60 + parseInt(startParts[1]) + parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);
    const hours = Math.floor(totalMins / 60) % 24;
    const minutes = totalMins % 60;
    return `${padZero(hours)}:${padZero(minutes)}`;
  }

  const getFormattedDate = () => {
    const day = ('0' + startDate.getDate()).slice(-2); // Get day of the month
    const month = ('0' + (startDate.getMonth() + 1)).slice(-2); // Get month (adding 1 since January is 0)
    const year = startDate.getFullYear(); // Get year
    return `${year}-${month}-${day}`; // Format date as 'YYYY-MM-DD'
  }

  return (
    <div>
      {isLoading && <Loader />}
      {showToast && <ToastPopup isShow={showToast} variant={tVariant} message={tMessage} />}
      <div className="play-and-membership">
        <div className="container-fluid g-0">
          <div className="facilities-wrapper">
            <div className="facility-left two" style={{ zIndex: "auto" }}>
              <div className="facility-img two">
                <img src="assets/img/play-and-membership.jpg" alt='Booking' />
              </div>
              <div className="feature two">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="single-feature py-5">
                      <i className="fas fa-cart-arrow-down fa-5x mb-4 text-light"></i>
                      <h2 className="text-light">Cart Is Empty</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="facility-right two b-form">
              <div className="membership-form two">
                <div className="title white two mt-5">
                  <h2>Book Your Slot Now!</h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="row mb-3">
                      <label className="col-sm-3 col-form-label text-white">Select Sport <span className='text-danger'>*</span>:</label>
                      <div className="col-sm-9">
                        <select {...register("sport_id", { required: true })} className="form-select mb-0 fw-bold">
                          {sports && sports.map((item, i) => <option key={i} value={item.id} className="fw-bold">{item.name}</option>)}
                        </select>
                      </div>
                      {errors.sport_id && <span className="text-danger fw-bold small">{errors.sport_id.message}</span>}
                    </div>
                    <div className="row mb-3">
                      <label className="col-sm-3 col-form-label text-white">Select Date <span className='text-danger fw-bold'>*</span>:</label>
                      <div className="col-sm-9">
                        {/* <input type="date" {...register("booking_date", { required: true })} className="mb-0 fw-bold" /> */}
                        <ReactDatePicker selected={startDate} minDate={new Date()} dateFormat={'yyyy-MM-dd'}
                          className="mb-0 fw-bold" onChange={(date) => setStartDate(date)} />
                        {errors.booking_date && <p className="text-warning small">* Select Date</p>}
                      </div>
                    </div>
                    {/* <div className="row mb-3">
                      <label className="col-sm-3 col-form-label text-white">Start Time <span className='text-danger'>*</span>:</label>
                      <div className="col-sm-9">
                        <input type="time" {...register("start_time", { required: true })} className="mb-0 fw-bold" />
                        {errors.start_time && <p className="text-warning small">* Select Start Time</p>}
                      </div>
                    </div> */}
                    <div className="row mb-3">
                      <label className="col-sm-3 col-form-label text-white">Start Time <span className='text-danger'>*</span>:</label>
                      <div className="col-sm-9">
                        <ReactDatePicker className="mb-0 fw-bold"
                          selected={startTime}
                          onChange={(date) => setStartTime(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          dateFormat="hh:mm aa"
                        />
                        {errors.start_time && <p className="text-warning small">* Select Start Time</p>}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-sm-3 col-form-label text-white">Duration <span className='text-danger'>*</span>:</label>
                      <div className="col-sm-9">
                        <i onClick={() => manageDuration("D")} className={duration > 1 ? "fas fa-minus mx-4" : "fas fa-minus mx-4 bg-dark"}></i>
                        <span>{duration} Hr</span>
                        <i onClick={() => manageDuration("I")} className="fas fa-plus mx-4"></i>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-sm-3 col-form-label text-white">Select Turf <span className='text-danger'>*</span>:</label>
                      <div className="col-sm-9">
                        <select {...register("court_id", { required: 'Select Turf' })} className="form-select mb-0 fw-bold">
                          {courts && courts.map((item, i) => <option key={i} className="fw-bold" value={item.id}>{item.court_name}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="button--wrap button--wrap-two mt-0">
                        <button className="eg-btn btn--primary golf-btn mx-auto fw-bold" type="submit" style={{ zIndex: "auto" }}>
                          Proceed
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
