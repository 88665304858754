import { SHA256 } from 'crypto-js';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { checkPaymentStatus, createBookings, createPayment } from '../../../services/ApiServices';
import { BOOKING_DETAILS, ONLINE_ORDER_DETAILS, PAYMENT_DETAILS, SALT_KEY, USER_ID } from '../../../constants/DataConstants';
import Loader from '../../common/Loader';
import { bookingSuccess, toastTimeStamp } from '../../../constants/ResponseConstants';
import ToastPopup from '../../modals/ToastPopup';

export default function BookingSuccess() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [phonpePaymentStatus, setPhonpePaymentStatus] = useState();

  // Toast State Start
  const [showToast, setShowToast] = useState(false);
  const [tMessage, setTMessage] = useState();
  const [tVariant, setTVariant] = useState();
  // Toast State End

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log("ONLINE_ORDER_DETAILS", ONLINE_ORDER_DETAILS);
    // console.log("PAYMENT_DETAILS", PAYMENT_DETAILS);
    // console.log("BOOKING_DETAILS", BOOKING_DETAILS);
    PAYMENT_DETAILS ? checkStatus(PAYMENT_DETAILS, ONLINE_ORDER_DETAILS) : navigate("/courts");
  }, []);

  const checkStatus = async (data, data1) => {
    setIsLoading(true);
    // const base64 = window.btoa(JSON.stringify(data));
    let saltIndex = 1;
    let string = `/pg/v1/status/${data.merchantId}/${data.merchantTransactionId}${SALT_KEY}`;
    let sha256 = SHA256(string).toString();
    let xVerify = sha256 + "###" + saltIndex;
    const response = await checkPaymentStatus(xVerify, data.merchantId, data.merchantTransactionId).catch(console.error);
    setPhonpePaymentStatus(response.data);
    if (response.data?.data?.state === "COMPLETED") {
      setIsCompleted(true);
      setIsPending(false);
      setIsFailed(false);
      saveOrder(response.data, data1);
      setIsLoading(false);
    } else if (response.data?.data?.state === "PENDING") {
      setIsCompleted(false);
      setIsPending(true);
      setIsFailed(false);
      paymentFailed(response.data, data1);
      setIsLoading(false);
    } else {
      setIsCompleted(false);
      setIsPending(false);
      setIsFailed(true);
      paymentFailed(response.data, data1);
      setIsLoading(false);
    }
  }

  const saveOrder = async (payDetails, onlineOrderDetails) => {
    setIsLoading(true);
    onlineOrderDetails.success = payDetails.success;
    onlineOrderDetails.Code = payDetails.code;
    onlineOrderDetails.MerchantTransactionId = payDetails?.data?.merchantTransactionId ? payDetails?.data?.merchantTransactionId : "";
    onlineOrderDetails.TransactionId = payDetails?.data?.transactionId ? payDetails?.data?.transactionId : "";
    const paymentData = {
      'user_id': USER_ID,
      'other_charges': onlineOrderDetails.OtherCharges,
      'net_total': onlineOrderDetails.NetTotal / 100,
      'payment_mode': onlineOrderDetails.PaymentMode,
      'payment_status': onlineOrderDetails.success || true,
      'booking_status': onlineOrderDetails.success || true,
      'code': onlineOrderDetails.Code,
      'merchant_trans_id': onlineOrderDetails.MerchantTransactionId,
      'trans_id': onlineOrderDetails.TransactionId || 'N/A',
      'success': onlineOrderDetails.success || true
    }
    const resPayment = await createPayment(paymentData).catch(console.error);
    const bookingData = {
      user_id: USER_ID,
      court_id: BOOKING_DETAILS.court_id,
      sport_id: BOOKING_DETAILS.sport_id,
      booking_date: BOOKING_DETAILS.booking_date,
      start_time: BOOKING_DETAILS.start_time,
      end_time: BOOKING_DETAILS.end_time,
      payment_id: resPayment.data.id,
      status_id: onlineOrderDetails.success || true,
      description: ''
    }
    const resBooking = await createBookings(bookingData).catch(console.error);
    if (resBooking.data.message === bookingSuccess) {
      localStorage.removeItem("ONLINE_ORDER_DETAILS");
      localStorage.removeItem("PAYMENT_DETAILS");
      setIsLoading(false);
      setShowToast(true);
      setTMessage('<b>' + bookingSuccess + '</b>.');
      setTVariant('success');
      setTimeout(() => setShowToast(false), toastTimeStamp);
    }
    setIsLoading(false);
  }

  const paymentFailed = async (payDetails, onlineOrderDetails) => {
    setIsLoading(true);
    onlineOrderDetails.Success = payDetails.success;
    onlineOrderDetails.Code = payDetails.code;
    onlineOrderDetails.MerchantId = payDetails?.data.merchantId ? payDetails?.data.merchantId : "";
    onlineOrderDetails.MerchantTransactionId = payDetails?.data.merchantTransactionId ? payDetails?.data.merchantTransactionId : "";
    onlineOrderDetails.TransactionId = payDetails?.data?.transactionId ? payDetails?.data?.transactionId : "";
    onlineOrderDetails.Amount = payDetails?.data?.amount;
    const paymentData = {
      'user_id': USER_ID,
      'other_charges': onlineOrderDetails.OtherCharges,
      'net_total': onlineOrderDetails.NetTotal / 100,
      'payment_mode': onlineOrderDetails.PaymentMode,
      'payment_status': onlineOrderDetails.success || false,
      'booking_status': onlineOrderDetails.success || false,
      'code': onlineOrderDetails.Code,
      'merchant_trans_id': onlineOrderDetails.MerchantTransactionId,
      'trans_id': onlineOrderDetails.TransactionId || 'N/A',
      'success': onlineOrderDetails.success || false
    }
    await createPayment(paymentData).catch(console.error);
    localStorage.removeItem("ONLINE_ORDER_DETAILS");
    localStorage.removeItem("PAYMENT_DETAILS");
    setIsLoading(false);
    setShowToast(true);
    setTMessage('<b>Something Went Wrong. Please Try Again.</b>.');
    setTVariant('danger');
    setTimeout(() => setShowToast(false), toastTimeStamp);
  }

  return (
    <div>
      {isLoading ? <Loader /> : null}
      {showToast && <ToastPopup isShow={showToast} variant={tVariant} message={tMessage} />}
      {isCompleted && (
        <div className="facilities-wrapper container my-5 rounded-3 gap-5">
          <div className="facility-right two b-form card">
            <div className='card'>
              <div className="card-header d-flex justify-content-center">
                <img src='assets\img\icons\booking-success.png' alt='Success' className='w-25' />
              </div>
              <div className="card-body text-center">
                <div className='d-flex justify-content-center'>
                  <i className='fa fa-check fs-4'></i>
                  <h3 className="ms-3 text-dark">Awesome..!</h3>
                </div>
                {/* <p className='fw-bold text-one'>Your booking has been confirmed.<br /> Check your email for detials.</p> */}
                <p className='fw-bold text-one'>Your booking has been confirmed.</p>
                {isCompleted && (
                  <div className="checkout-confirm">
                    {/* <img src="assets/images/okay.png" alt="Okay" /> */}
                    <h3>Payment Complete</h3>
                    <h4>{phonpePaymentStatus?.message}</h4>
                    <p>Merchant Trans. ID:{" "}<strong>{phonpePaymentStatus?.data?.merchantTransactionId}</strong>{" "}</p>
                    <p> Transaction ID:{" "}<strong>{phonpePaymentStatus?.data?.transactionId}</strong>{" "}</p>
                    <div className="clearfix form-action">
                      <Link to="/user/booking" type="submit" className="btn btn-accent min-width">View Booking</Link>
                    </div>
                  </div>
                )}
                <div className="btn-block mb-sm-1 mb-md-0 d-flex justify-content-center">
                  <div className="membership-btn">
                    <Link to="/courts" style={{ 'zIndex': 'auto' }}><i className="fa fa-check"></i>
                      &nbsp; OK</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}


      {isPending && (
        <div className="facilities-wrapper container my-5 rounded-3 gap-5">
          <div className="facility-right two b-form card bg-warning">
            <div className='card'>
              <div className="card-header d-flex justify-content-center">
                <img src='assets\img\icons\booking-pending.png' alt='Success' className='w-25' />
              </div>
              <div className="card-body text-center">
                <div className='d-flex justify-content-center'>
                  <i className='fa fa-close text-warning fs-4'></i>
                  <h3 className="ms-3 text-dark">Booking Pending..!</h3>
                </div>
                {/* <p className='fw-bold text-one'>Your booking has been pending.<br /> Check your email for detials.</p> */}
                <p className='fw-bold text-one'>Your booking has been pending. Please try again.</p>
                {isPending && (
                  <div className="checkout-confirm">
                    {/* <img src="assets/images/info.png" alt="Okay" /> */}
                    <h3>Payment Pending.</h3>
                    {/* <h4>{phonpePaymentStatus?.code} | {phonpePaymentStatus?.message}</h4> */}
                    <p>Merchant Trans. ID:{" "}<strong>{phonpePaymentStatus?.data?.merchantTransactionId}</strong>{" "}</p>
                    <p>Transaction ID:{" "}<strong>{phonpePaymentStatus?.data?.transactionId}</strong>{" "}</p>
                    {/* <div className="clearfix form-action">
                      <Link to="/booking-summary" type="submit" className="btn btn-accent min-width">Goto Checkout</Link>
                    </div> */}
                  </div>
                )}
                <div className="btn-block mb-sm-1 mb-md-0 d-flex justify-content-center">
                  <div className="membership-btn">
                    <Link to="/booking-summary" style={{ 'zIndex': 'auto' }} className='bg-warning'><i className="fa fa-exclamation" style={{ width: 30 }}></i>
                      &nbsp; Try Again</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}


      {isFailed && (
        <div className="facilities-wrapper container my-5 rounded-3 gap-5">
          <div className="facility-right two b-form card bg-danger">
            <div className='card'>
              <div className="card-header d-flex justify-content-center">
                <img src='assets\img\icons\booking-failed.png' alt='Success' className='w-25' />
              </div>
              <div className="card-body text-center">
                <div className='d-flex justify-content-center'>
                  <i className='fa fa-close text-danger fs-4'></i>
                  <h3 className="ms-3 text-dark">Booking Failed..!</h3>
                </div>
                {/* <p className='fw-bold text-one'>Your booking has been failed.<br /> Check your email for detials.</p> */}
                {isFailed && (
                  <div className="checkout-confirm">
                    {/* <img src="assets/images/info.png" alt="Okay" /> */}
                    {/* <h3>Payment Failed.</h3>
                    <h4>{phonpePaymentStatus?.code} | {phonpePaymentStatus?.message}</h4> */}
                    <p>Merchant Trans. ID:{" "}<strong>{phonpePaymentStatus?.data?.merchantTransactionId}</strong>{" "}</p>
                    <p>Transaction ID:{" "}<strong>{phonpePaymentStatus?.data?.transactionId}</strong>{" "}</p>
                    {/* <div className="clearfix form-action">
                      <Link to="/booking-summary" type="submit" className="btn btn-accent min-width">Place Order</Link>
                    </div> */}
                  </div>
                )}
                <div className="btn-block mb-sm-1 mb-md-0 d-flex justify-content-center">
                  <div className="membership-btn">
                    <Link to="/booking-summary" style={{ 'zIndex': 'auto' }} className='bg-danger'><i className="fa fa-close"></i>
                      &nbsp; Try Again</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
    </div>
  )
}