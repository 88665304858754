import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import { useForm } from "react-hook-form";
import { adminLogin } from "../../../services/ApiServices";
import { adminLoginSuccess, toastTimeStamp } from "../../../constants/ResponseConstants";
import ToastPopup from "../../modals/ToastPopup";

export default function Login() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // Toast State Start
  const [showToast, setShowToast] = useState(false);
  const [tMessage, setTMessage] = useState();
  const [tVariant, setTVariant] = useState();
  // Toast State End

  const onSubmit = async (data) => {
    setIsLoading(true);
    const response = await adminLogin(data).catch(console.error);
    console.log("response: " + response);
    if (response.message === adminLoginSuccess) {
      localStorage.setItem('adminLogin', JSON.stringify(response.admin));
      toastMessage(response.message, 'success');
      navigate('/admin');
      window.location.reload();
    } else {
      toastMessage(response.message, 'danger');
    }
    setIsLoading(false);
  }

  const toastMessage = (m, v) => {
    setShowToast(true);
    setTMessage('<b>' + m + '</b>.');
    setTVariant(v);
    setTimeout(() => setShowToast(false), toastTimeStamp);
  }

  return (
    <div>
      {isLoading && <Loader />}
      {showToast && <ToastPopup isShow={showToast} variant={tVariant} message={tMessage} />}
      <div className="play-and-membership">
        <div className="container-fluid g-0">
          <div className="facilities-wrapper">
            {/* <div className="facility-left two" style={{ zIndex: "auto" }}>
              <div className="facility-img two">
                <img src="assets/img/play-and-membership.jpg" alt='Booking' />
              </div>
              <div className="feature two">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="single-feature py-5">
                      <i className="fas fa-cart-arrow-down fa-5x mb-4 text-light"></i>
                      <h2 className="text-light">Cart Is Empty</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="facility-right two b-form">
              <div className="membership-form two">
                <div className="title white two mt-5">
                  <h2>Login To Admin Dashboard!</h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="row mb-3">
                      <label className="col-sm-4 col-form-label text-white mt-1">Mobile Number:</label>
                      <div className="col-sm-8">
                        <input type="text" {...register("contact_number_one", { required: true })} className="mb-0 fw-bold" />
                        {errors.contact_number_one && (<div className="text-warning fw-bold small">* Mobile Number Required.</div>)}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-sm-4 col-form-label text-white mt-g-1">Password:</label>
                      <div className="col-sm-8">
                        <input type="password" {...register("password", { required: true })} className="mb-0 fw-bold" />
                        {errors.password && (<div className="text-warning fw-bold small">* Password Field Required.</div>)}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="button--wrap button--wrap-two mt-0">
                        <button className="eg-btn btn--primary golf-btn mx-auto fw-bold" type="submit" style={{ zIndex: "auto" }}>
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
