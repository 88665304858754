import React, { useEffect, useState } from 'react';
import { getBookings, getCourts } from '../../../services/ApiServices';
import { useForm } from 'react-hook-form';
import { USER_ID } from '../../../constants/DataConstants';
import { Modal } from 'react-bootstrap';

export default function MyBookings() {

  const [bookings, setBookings] = useState([]);
  const [courts, setCourts] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [modalItem, setModalItem] = useState({});
  
  const { register, handleSubmit } = useForm();
  const defaultData = { CourtID: 0, DateFrom: '', DateTo: '', UserID: USER_ID };
  const { court_name, description, start_time, end_time, duration, net_total, other_charges, payment_mode, payment_status, trans_id, merchant_trans_id, booking_status } = modalItem;

  useEffect(() => {
    window.scrollTo(0, 0);
    onSubmit(defaultData);
    getAllCourts();
  }, []);

  const onSubmit = async (data) => {
    await getAllBookings(data);
  }

  const getAllBookings = async (data) => {
    const formData = {
      'CourtID': data?.CourtID,
      'DateFrom': data?.DateFrom,
      'DateTo': data?.DateTo,
      'UserID': USER_ID
    }
    const response = await getBookings(formData).catch(console.error);
    setBookings(response.data);
  }

  const getAllCourts = async () => {
    const response = await getCourts().catch(console.error);
    setCourts(response.data);
  }

  const DetailsModal = () => {
    return (
      <Modal backdrop="static" show={showDetails} onHide={() => setShowDetails(false)} keyboard={false}>
        <Modal.Header closeButton><Modal.Title>Booking Details</Modal.Title></Modal.Header>
        <Modal.Body className='bg-one rounded-2 text-white'>
          <div className="d-flex my-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i><span className='fw-bold'>Turf Name: &nbsp;</span> {court_name}
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i><span className='fw-bold'>Description: &nbsp;</span> {description}
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i><span className='fw-bold'>Time: &nbsp;</span> {start_time} - {end_time}
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i><span className='fw-bold'>Duration: &nbsp;</span> {duration}
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i><span className='fw-bold'>Booking Status: &nbsp;</span> {booking_status}
          </div>
          <h4>Payment Details:</h4>
          <table className='table border border-2 border-warning'>
            <tr>
              <td className='text-center'>
                <div className="d-flex align-items-center">
                  Paid Amount: &nbsp;<span className='h4 mb-0'>{net_total}</span>
                </div>
              </td>
              <td className='text-center'>
                <div className="d-flex align-items-center">Other Charges: &nbsp;<span className='h4 mb-0'>{other_charges}</span></div>
              </td>
            </tr>
          </table>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i><span className='fw-bold'>Payment Mode: &nbsp;</span> {payment_mode}
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i><span className='fw-bold'>Payment Status: &nbsp;</span> {payment_status}
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i><span className='fw-bold'>Transaction ID.: &nbsp;</span> {trans_id}
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i><span className='fw-bold'>Merchant Transaction ID.: &nbsp;</span> {merchant_trans_id}
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <div>
      {bookings ?
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row mb-3'>
              <div className="row g-1 align-items-center">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <select className="form-select" {...register("CourtID", { required: false })}>
                    <option value={0}>Select Court</option>
                    {courts.map((item, i) => <option key={i} value={item.id}>{item.court_name}</option>)}
                  </select>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <input type="date" className="form-control" {...register("DateFrom", { required: false })} />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <input type="date" className="form-control" {...register("DateTo", { required: false })} />
                </div>
                <div className="button--wrap button--wrap-two col-lg-2 col-md-6 col-sm-12 mt-0">
                  <button type="submit" className="eg-btn btn--primary golf-btn mx-auto py-2">
                    <i className="fas fa-arrow-right ms-0 me-2"></i>Apply</button>
                </div>
              </div>
            </div>
          </form>
          {bookings && bookings.map((item, i) => (
            <div className="single-information" key={i}>
              <div className="row w-100">
                <div className="col-lg-8">
                  <div className="info-cnt b-info mb-lg-0 mb-2">
                    <h5>Turf Name: {item.court_name}</h5>
                    <p><span className='fw-bold text-one'>Date:</span> {item.booking_date}
                      <span className='fw-bold ms-4 text-one'>  Time:</span> {item.start_time} - {item.End_Time}</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="button--wrap button--wrap-two mt-1">
                    <button className="eg-btn btn--primary golf-btn mx-auto" type='button'
                      onClick={() => { setShowDetails(true); setModalItem(item); }}>View More</button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div> :
        <div className='text-center'>
          <img src='/assets/img/r-not-found.png' alt='Not-Found' />
        </div>
      }
      {showDetails && <DetailsModal />}
    </div >
  )
}
