/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getAdmin,
  getOffersData,
  getServices,
} from "../../../services/ApiServices";
import ServicesModal from "../../modals/ServicesModal";
import Loader from "../../common/Loader";
import OfferModal from "../../modals/OfferModal";

export default function Home() {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    getAllServices();
    getAdminData();
    getOffers();
    setIsLoading(false);
  }, []);

  const [services, setServices] = useState([]);
  const [oneServices, setOneServices] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [offers, setOffers] = useState([]);
  const [offer, setOffer] = useState([]);

  // Modal Members & Functions Start
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setOneServices(data);
  };
  // Modal Members & Functions End

  // Modal Members & Functions Start
  const [showOffer, setShowOffer] = useState(false);
  const handleCloseOffer = () => setShowOffer(false);
  const handleShowOffer = (data) => {
    setShowOffer(true);
    setOffer(data);
  };
  // Modal Members & Functions End

  const getOffers = async () => {
    const response = await getOffersData(localStorage.getItem("limit")).catch(console.error);
    setOffers(response.data);
  };

  const getAllServices = async () => {
    const response = await getServices().catch(console.error);
    setServices(response.data);
  };

  const getAdminData = async () => {
    const response = await getAdmin(1).catch(console.error);
    setOrgData(response.data);
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Increment the current slide index
      setCurrentSlide((prevSlide) => (prevSlide + 1) % offers.length);
    }, 3000); // Adjust the interval time as needed (in milliseconds)

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [offers.length]);

  const handleIndicatorClick = (index) => {
    // Set the current slide to the clicked indicator index
    setCurrentSlide(index);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div>
        {/* <div className="hero-area two" style={{ 'zIndex': 'auto', backgroundImage: `url(${lData.data.slider.bg_image})` }}> */}
        <div className="hero-area two" style={{ zIndex: "auto" }}>
          <div className="social-links mt-3">
            <div className="follow-us">
              <span>FOLLOW US</span>
              <ul className="follow-social">
                <li>
                  <a href={orgData.whatsapp}>
                    <i className="fab fa-whatsapp"></i>
                  </a>
                </li>
                <li>
                  <a href={orgData.instagram}>
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href={orgData.twitter}>
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href={orgData.facebook}>
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="hero-wrapper">
            <div className="container">
              <div className="hero-content">
                <h5 className="mb-0 text-light">Love Getting More Fun?</h5>
                <h2 className="hero-content-title">
                  Welcome To <br />{" "}
                  <span className="letters">{orgData.org_name}.</span>
                </h2>
                <ul className="tags">
                  <li>
                    <Link to="/">Tournament</Link>
                  </li>
                  <li>
                    <Link to="/">Course</Link>
                  </li>
                  <li>
                    <Link to="/">Training</Link>
                  </li>
                  <li>
                    <Link to="/">Playground</Link>
                  </li>
                </ul>
                <p>
                  Unleash your cricketing passion on our pristine turf.
                  Experience the perfect pitch htmlFor unparalleled sporting
                  thrills and unforgettable moments!
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="why-we-are">
          <div className="container z-n1">
            <div className="member-facilities two" style={{ 'zIndex': 'auto' }}>
              <div className="row justify-content-around">

                <div className="col-auto">
                  <div className="member-facility">
                    <div className="icon">
                      <img src="/assets/img/icons/facilityOrange-icon-1.svg" alt='MyTurf' /></div>
                    <a href="facility.html">Snooker Table</a>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="member-facility">
                    <div className="icon">
                      <img src="/assets/img/icons/facilityOrange-icon-2.svg" alt='MyTurf' /></div>
                    <a href="facility.html">Table Tennis</a>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="member-facility">
                    <div className="icon">
                      <img src="/assets/img/icons/facilityOrange-icon-3.svg" alt='MyTurf' /></div>
                    <a href="facility.html">Locker Room</a>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="member-facility">
                    <div className="icon">
                      <img src="/assets/img/icons/facilityOrange-icon-4.svg" alt='MyTurf' /></div>
                    <a href="facility.html">CCTV Surveillance</a>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="member-facility">
                    <div className="icon">
                      <img src="/assets/img/icons/facilityOrange-icon-5.svg" alt='MyTurf' /></div>
                    <a href="facility.html">Waiting Area</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7 col-xl-6 or1">
                <div className="why-left">
                  <div className="title two">
                    <span>why we are</span>
                    <h2>We are here to bring the cricket near to you.</h2>
                  </div>
                  <div className="why-boxes">
                    <div className="why-box">
                      <div className="icon">
                        <img src="/assets/img/icons/why-icon-1.svg" alt='MyTurf' /></div>
                      <b>Analyze</b>
                    </div>
                    <div className="why-box">
                      <div className="icon">
                        <img src="/assets/img/icons/why-icon-2.svg" alt='MyTurf' /></div>
                      <b>Learn</b>
                    </div>
                    <div className="why-box">
                      <div className="icon">
                        <img src="/assets/img/icons/why-icon-3.svg" alt='MyTurf' /></div>
                      <b>Compete</b>
                    </div>
                  </div>
                  <p>Welcome to our amazing cricket turf, where cricket dreams come true! Surrounded by beautiful scenery, our well-kept turf offers a fantastic sports experience. Whether you're a pro or just love cricket, our top-notch pitches ensure fair play. We have modern facilities and great equipment htmlFor individual practice, team training, and exciting matches. Our friendly staff is here to help you have a fun time on the turf. Come join us htmlFor an awesome cricketing experience and enjoy playing on a perfect pitch that's all about passion htmlFor the game.</p>
                  <ul className="field-points">
                    <li><i className="bi bi-check2-circle"></i> Pristine Pitch Conditions.</li>
                    <li><i className="bi bi-check2-circle"></i> State-of-the-Art Facilities.</li>
                    <li><i className="bi bi-check2-circle"></i> Scenic Surroundings.</li>
                    <li><i className="bi bi-check2-circle"></i> Professional-Grade Equipment.</li>
                    <li><i className="bi bi-check2-circle"></i> Expert Staff Support.</li>
                    <li><i className="bi bi-check2-circle"></i> Unparalleled Sporting Experience.</li>
                  </ul>
                  <div className="button--wrap button--wrap-two">
                    <a className="eg-btn btn--primary golf-btn mx-auto" href="about.html">Read More</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-xl-6 or2">
                <div className="why-right">
                  <div className="learn-img">
                    <img src="/assets/img/why-right.jpg" alt='MyTurf' /></div>
                  <div className="why-right-boxes">
                    <div className="why-right-box">
                      <div className="icon">
                        <img src="/assets/img/icons/why-icon-1.svg" alt='MyTurf' /></div>
                      <b>Analyze</b>
                    </div>
                    <div className="why-right-box">
                      <b>Learn</b>
                      <div className="icon">
                        <img src="/assets/img/icons/why-icon-2.svg" alt='MyTurf' /></div>
                    </div>
                    <div className="why-right-box">
                      <div className="icon">
                        <img src="/assets/img/icons/why-icon-3.svg" alt='MyTurf' /></div>
                      <b>Compete</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        
        {offers && offers.length > 0 && (
        <>
          <div className="title two my-3 text-center">
            <span>On Going Offers</span>
          </div>
            <div className="slider-container">
              <div className="slider mb-4 mt-2">
                {offers.map((slide, index) => (
                  <>
                    <a
                      key={index}
                      className={`slide ${
                        index === currentSlide ? "active" : ""
                      }`}
                      onClick={() => handleShowOffer(offers[currentSlide])}
                    >
                      {offers[currentSlide].OfferDescription}
                      <h6 className="mb-0">
                        {offers[currentSlide].SpecialDayDate}
                      </h6>
                    </a>
                  </>
                ))}
              </div>
  
              <div className="indicators">
                {offers.map((_, index) => (
                  <span
                    key={index}
                    className={`indicator ${
                      index === currentSlide ? "active" : ""
                    }`}
                    onClick={() => handleIndicatorClick(index)}
                  ></span>
                ))}
              </div>
            </div>
        </>
        )}

        <div className="about-area two sec-mar mt-5">
          <div className="container">
            <div className="row gx-4">
              <div className="col-xl-6">
                <div className="about-left two">
                  <img
                    src="/assets/img/cricket-turf-booking.jpg"
                    alt="MyTurf"
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="about-right two">
                  <div className="title two">
                    <span>About Us</span>
                    <h2>The Perfect Pitch: Get to Know Our Turf</h2>
                  </div>
                  <p>
                    {" "}
                    Welcome to our premier cricket turf, where the love htmlFor
                    the game meets unparalleled excellence. With a passion
                    htmlFor cricket ingrained in our very soul, we have crafted
                    a sporting haven htmlFor enthusiasts of all ages and skill
                    levels. Our state-of-the-art facilities, meticulously
                    maintained pitches, and scenic surroundings create the
                    perfect ambiance htmlFor thrilling matches and memorable
                    moments. Whether you're a seasoned cricketer looking to hone
                    your skills or a group of friends seeking friendly
                    competition, our dedicated staff is here to ensure an
                    exceptional experience. Join us in celebrating the spirit of
                    cricket and relish the joy of playing on our extraordinary
                    turf.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="play-and-membership">
          <div className="container-fluid g-0">
            <div className="facilities-wrapper">
              <div className="facility-left two" style={{ zIndex: "auto" }}>
                <div className="facility-img two">
                  <img src="/assets/img/play-and-membership.jpg" alt="MyTurf" />
                </div>
                <div className="feature two">
                  <div className="row g-4">
                    <div className="title white two">
                      <span>Services</span>
                      <h2>
                        Cricketing Paradise: Our Premier Ground Providing Above
                        Services!
                      </h2>
                    </div>
                    {services.map((item, i) => (
                      <div
                        className="col-md-4"
                        key={i}
                        onClick={() => handleShow(item)}
                      >
                        <div className="single-feature">
                          <b>{item.name}</b>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="course-layout sec-mar-top mb-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 or2">
                <div className="course-wrapper">
                  <div className="title two">
                    <span>Cricket Turf Layout</span>
                    <h2>Play Our Turf Feel Free</h2>
                  </div>
                  <p>
                    Our cricket ground boasts a meticulously designed layout to
                    ensure an exceptional sporting experience. Spanning across
                    lush greenery, the field is vast, allowing htmlFor various
                    formats of the game. The centrally positioned pitch is
                    crafted with precision, meeting professional standards
                    htmlFor bounce and turn. <br />
                    Surrounding the pitch is a well-maintained outfield,
                    offering smooth running and excellent fielding
                    opportunities. Strategically placed sight screens ensure
                    optimal visibility htmlFor batsmen, and the boundary ropes
                    mark the perimeter htmlFor thrilling sixes and fours. <br />
                    For the spectators, comfortable seating areas are arranged
                    along with shaded spots to enjoy the matches. Additionally,
                    our ground features modern amenities, including changing
                    rooms, practice nets, and a pavilion to cater to players'
                    needs. <br />
                    Whether you're an aspiring cricketer, a seasoned player, or
                    a passionate fan, our thoughtfully laid-out cricket ground
                    promises to enhance your love htmlFor the game and create
                    unforgettable moments.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 or1">
                <div className="golf-field">
                  <img src="/assets/img/field.png" alt="MyTurf" />
                  <span>Cricket Turf</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {show && (
        <ServicesModal
          data={oneServices}
          handleClose={handleClose}
          show={show}
        />
      )}

      {showOffer && (
        <OfferModal
          data={offer}
          handleCloseOffer={handleCloseOffer}
          showOffer={showOffer}
        />
      )}
    </>
  );
}
