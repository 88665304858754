import React, { useEffect, useState } from 'react'
import { getAdmin } from '../../../services/ApiServices';
import { Link } from 'react-router-dom';

export default function SupportPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0); getAdminData();
  }, []);

  const [orgData, setOrgData] = useState([]);

  const getAdminData = async () => {
    const response = await getAdmin(1).catch(console.error);
    setOrgData(response.data);
  }

  return (
    <div className='container my-5'>
      <h4>Support Policy for BnB Turf</h4>
      <p>At BnB Turf, we strive to provide our users with the best possible support experience. Our Support Policy outlines the guidelines and procedures for accessing and utilizing our support services.</p>
      <ol type='1'>
        <li className='fw-bold h5 mt-4'>Support Channels:</li>
        <ul>
          <li>We offer support through multiple channels, including email, phone, and live chat.</li>
          <li>For non-urgent inquiries or general questions, you can reach us through email or live chat during our regular support hours.</li>
          <li>For urgent matters or immediate assistance, we recommend contacting us by phone.</li>
        </ul>
        <li className='fw-bold h5 mt-4'>Support Hours:</li>
        <p>Our support services are available during the following hours:</p>
        <p className='fw-bold'>Monday to Friday: 06:00 AM - 10:00 PM (local time)</p>
        <p>Please note that our support services may be limited or unavailable on weekends and public holidays.</p>
        <li className='fw-bold h5 mt-4'>Types of Support:</li>
        <ul>
          <li>General Inquiries: We are happy to assist with general questions about the BnB Turf website, features, and functionalities.</li>
          <li>Technical Support: We provide technical assistance for any issues or errors you encounter while using the website.</li>
          <li>Account Assistance: If you have any account-related questions or need help with managing your account, we are here to help.</li>
          <li>Booking Support: If you require assistance with a specific booking, such as cancellations, modifications, or refund requests, please reach out to us.</li>
        </ul>
        <li className='fw-bold h5 mt-4'>Response Time:</li>
        <ul>
          <li>We strive to respond to all support inquiries as promptly as possible.</li>
          <li>Our target response time for non-urgent inquiries is within 24 hours during our support hours.</li>
          <li>For urgent matters or issues affecting your immediate use of the website, we aim to provide a faster response.</li>
        </ul>
        <li className='fw-bold h5 mt-4'>Information Required:</li>
        <p>To help us assist you more effectively, please provide the following information when contacting our support team:</p>
        <ul>
          <li>Your full name and contact information</li>
          <li>A detailed description of the issue or inquiry</li>
          <li>Any relevant screenshots or error messages</li>
          <li>Your booking ID (if applicable)</li>
          <li>Any steps you have already taken to resolve the issue</li>
        </ul>
        <li className='fw-bold h5 mt-4'>Escalation Process:</li>
        <p>If your issue is not resolved or if you have concerns about the support received, you may request to escalate the matter. Our support team will escalate the issue to a higher level of support or management for further review and resolution.</p>
        <li className='fw-bold h5 mt-4'>Feedback and Suggestions:</li>
        <p>We welcome your feedback and suggestions on how we can improve our support services. Please feel free to share any ideas or recommendations to help us enhance your support experience.</p>
        <li className='fw-bold h5 mt-4'>Limitations:</li>
        <ul>
          <li>While we make every effort to assist and resolve issues, please note that there may be limitations to what support we can provide.</li>
          <li>We cannot provide support for issues caused by third-party services, applications, or hardware not directly related to the BnB Turf website.</li>
          <li>We reserve the right to refuse or limit support services for users who violate our Terms of Service or engage in abusive or inappropriate behavior towards our support team.</li>
        </ul>
        <li className='fw-bold h5 mt-4'>Changes to Support Policy:</li>
        <p>If you have any questions or require support, please contact us at:</p>
        <li className='fw-bold h5 mt-4'>Contact Information:</li>
        <p>If you have any questions, concerns, or feedback regarding these Terms, please contact us at:</p>
        {orgData && <>
          Email ID.: <Link to={`mailto:${orgData.email_id}`} className='text-dark fw-bold'>{orgData.email_id}</Link><br />
          <div className='d-flex'>Phone No.:
            <div className='ms-1'>
              <Link to={`tel:${orgData.contact_number_one}`} className='text-dark fw-bold'>{orgData.contact_number_one}</Link><br />
              <Link to={`tel:${orgData.contact_number_two}`} className='text-dark fw-bold'>{orgData.contact_number_two}</Link>
            </div>
          </div>
          <div className='d-flex'>Address:<div className='text-dark fw-bold ms-1'> {orgData.address_line}{', '}{orgData.city}{', '}{orgData.city}{', '}{orgData.district}{', '}{orgData.state}{' - '}{orgData.pin_code}</div></div>
        </>}
        <p>We appreciate your cooperation and thank you for choosing BnB Turf.</p>
      </ol>
    </div>
  )
}