import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

function SideBars(props) {
  const data = props.data;
  const location = useLocation();
  const navigate = useNavigate();

  const onLogout = () => {
    localStorage.removeItem('adminLogin');
    navigate('/login');
    window.location.reload();
  }

  return (
    <>
      <div className="admin-sidebar">
        <div className="">
          <div className="sidebar-widget px-0 py-3">
            {data.map((item, i) => (
              <div className={`blog-post mb-1 ${location.pathname === item.link && 'active'}`} key={i}>
                <NavLink to={item.link}>
                  <h5 className="my-0"> <i className={`fa ${item.icon} text-dark me-3`} /> {item.title}</h5>
                </NavLink>
              </div>
            ))}
            <div className={`blog-post mb-1 active`}>
              <button onClick={() => onLogout()} className="w-100 p-2">
                <i className='fa-solid fa-right-from-bracket text-dark me-3' /> Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBars;
