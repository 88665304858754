import { Modal } from "react-bootstrap";
import { ImagePath } from "../../../services/ShortFunctions";

export default function OfferModal(props) {
  const convertTime = (t) => {
    try {
      const inputDate = new Date(`2000-01-01T${t}`);
      const convertedTimeString = inputDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      return convertedTimeString;
    } catch (error) {
      console.error("Invalid time format:", error.message);
      return "Invalid time format";
    }
  };

  return (
    <>
      <Modal
        show={props.showOffer}
        onHide={props.handleCloseOffer}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.data.OfferImagePath !== null && (
            <div className="text-center">
              <img
                src={ImagePath(props.data.OfferImagePath)}
                alt="Offer Banner"
                className="w-50"
              />
            </div>
          )}
          <div className="single-post">
            <div className="post-thumbnail">
              <div className="news-cnt">
                <div className="d-flex align-items-center justify-content-center">
                  <p className="mb-0 fw-bold text-success fs-5">
                    {props.data.PriceVariation}%
                  </p>
                  &nbsp;off on booking
                </div>
                <h3><a href='#'>{props.data.OfferDescription}</a></h3>
                <p className="mb-0 d-flex">
                  Special Day Date: &nbsp;
                  <p className="mb-0 fw-bold">{props.data.SpecialDayDate}</p>
                </p>
                <p className="mb-0 d-flex">
                  Turf Time: &nbsp;
                  <p className="mb-0 fw-bold">
                    {convertTime(props.data.open_time)}&nbsp; to &nbsp;
                    {convertTime(props.data.close_time)}
                  </p>
                </p>
                <p className="mb-0 d-flex">
                  Court Name: &nbsp;
                  <p className="mb-0 fw-bold">{props.data.court_name}</p>
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>

        {/* <Modal.Footer>
          <div className="button--wrap">
            <button type="submit" className="eg-btn btn--primary golf-btn mx-auto p-2 px-3">Okay <i className="bi bi-arrow-right"></i></button>
          </div>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
